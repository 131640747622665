<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
              <CInput
                :placeholder="$t('search_a') + $t('name')"
                v-model="searchData.name"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getMessages()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createMessageModal = { modal: true }">{{ $t('create_message') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="content" slot-scope="props">
              {{ props.row.content.length > 50 ? props.row.content.substring(0, 50) + '...' : props.row.content }}
            </font>
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editMessageModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteMessageModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreateMessageModal :data="createMessageModal.data" :show="createMessageModal.modal" @showUpdate="show => createMessageModal.modal = show" @formSubmit="getMessages()" />

      <EditMessageModal :data="editMessageModal.data" :show="editMessageModal.modal" @showUpdate="show => editMessageModal.modal = show" @formSubmit="getMessages()" />

      <DeleteMessageModal :data="deleteMessageModal.data" :show="deleteMessageModal.modal" @showUpdate="show => deleteMessageModal.modal = show" @formSubmit="getMessages()" />

    </CCol>
  </CRow>
</template>

<script>
import CreateMessageModal from './modal/CreateMessageModal'
import EditMessageModal from './modal/EditMessageModal'
import DeleteMessageModal from './modal/DeleteMessageModal'

export default {
  name: 'messages',
  components: {
    CreateMessageModal,
    EditMessageModal,
    DeleteMessageModal,
  },
  data () {
    return {
      columns: [ 'admin_name', 'name', 'content', 'enabled', 'created_at', 'action' ],
      data: [],
      options: {
        headings: {
          admin_name: this.$t('account_name'),
          name: this.$t('name'),
          content: this.$t('content'),
          enabled: this.$t('enable'),
          created_at: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'admin_name', 'name', 'content', 'created_at', 'enabled' ],
        filterable: [ 'admin_name', 'name', 'content', 'created_at', 'enabled' ]
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { name: '' },
      createMessageModal: { data: {}, modal: false },
      editMessageModal: { data: {}, modal: false },
      deleteMessageModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getMessages()
  },
  methods: {
    getMessages () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getMessages', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
